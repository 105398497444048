import React, { useContext, useEffect, useRef, useState } from "react";
import { TbArrowUpRight } from "react-icons/tb";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FiArrowUpRight } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import GenericLayout from "../../common/layout/GenericLayout";
import { ThemeContext } from "../../context/ThemeContext";
import TableFilter from "../../common/Tables/TableFilter";
import SkeletonDataTable from "../../modules/SkeletonAnimation/SkeletonDataTable";
import { getCSPMFilters, getUserDetails } from "../../redux-toolkit/redux/auth";
import { cspmAPI } from "../../Api/CSPM/api";
import DetailedView from "./DetailedView";
import {
  SEVERITY_COLOR,
  SEVERITY_TEXT,
  STATUS_COLOR,
  STATUS_TEXT,
} from "./variables";
import paginationTemplate from "../../utils/paginationTemplate";
import { getFilter } from "../../utils/utils";

export default function CPSMTable(props) {
  const navigate = useNavigate();
  const { slug } = useParams();
  const currentUrl = window.location.href;

  const ref = useRef(null);
  const { state } = useLocation();

  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);
  const scan = userDetails.cpsm_scan_id;
  const cspmFilters = useSelector(getCSPMFilters);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState(null);
  const [filterParams, setFilterParams] = useState({
    scan,
    ...state?.filter,
  });
  const [currentDetails, setCurrentDetails] = useState(null);
  const [globalFilter, setGlobalFilter] = useState(null);

  const getFilterData = () => {
    cspmAPI
      .get("/findings/filter/", {
        scan,
        ...state?.filter,
        resource__labels__contains: JSON.stringify(cspmFilters),
      })
      .then((res) => {
        setFilterData(res.data);
        // setFilterParams({ ...filterParams, ...res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    setLoading(true);
    cspmAPI
      .post("/findings/filter/", {
        ...getFilter(filterData, filterParams),
        ...state?.filter,
        resource__labels__contains: cspmFilters,
        scan,
      })
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getFilterData();
  }, [scan]);

  useEffect(() => {
    if (filterData) getData();
  }, [filterParams, filterData]);

  const columns = [
    { field: "message", header: "Check" },
    {
      field: "severity",
      header: "Severity",
    },
    {
      field: "status",
      header: "Status",
    },
    { field: "resource_info.region", header: "Region" },
    { field: "resource_info.group.name", header: "Service" },
  ];

  const filterColumns = [
    {
      field: "severity",
      header: "Severity",
      type: "select",
      value: filterData?.severity?.map((item) => {
        return { label: SEVERITY_TEXT[item], value: item };
      }),
    },
    {
      field: "status",
      header: "Status",
      type: "select",
      value: filterData?.status?.map((item) => {
        return { label: STATUS_TEXT[item], value: item };
      }),
    },
    {
      field: "resource__region",
      header: "Region",
      value: filterData?.resource__region,
    },
    {
      field: "resource__group__name",
      header: "Service",
      value: filterData?.resource__group__name,
    },
  ];

  const returnTable = () => {
    const CSPM_COMPLIANCE_SLUGS = [
      "ISO27001-2013",
      "SOC2",
      "PCI-3.2.1",
      "GDPR",
      "HIPAA",
      "CISA",
      "NIST-800-53-Revision-5",
      "NIST-CSF-1.1",
      "FedRamp-Moderate-Revision-4",
      "FedRAMP-Low-Revision-4",
      "RBI-Cyber-Security-Framework",
      "FFIEC",
    ];
    if (
      userDetails.role === "Editor" &&
      CSPM_COMPLIANCE_SLUGS.includes(slug) &&
      !userDetails.module.includes("Compliance - CSPM")
    )
      return <div className="flex justify-center">Forbidden</div>;
    else if (
      userDetails.role === "Editor" &&
      !CSPM_COMPLIANCE_SLUGS.includes(slug) &&
      !userDetails.module.includes("Services")
    )
      return <div className="flex justify-center">Forbidden</div>;

    return (
      <>
        <DetailedView
          currentDetails={currentDetails}
          setCurrentDetails={setCurrentDetails}
        />
        <div
          className={`rounded-2xl userTable-style  ${
            isDarkMode
              ? "header-dark  text-white"
              : "header-light white-background  text-color"
          }`}
        >
          <div className="flex flex-wrap  dashboard-table">
            <p className="font-bold">
              {state?.title} <span> ({data.length || 0}) </span>
            </p>
          </div>
          <TableFilter
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setFilterParams={setFilterParams}
            filterParams={filterParams}
            columns={filterColumns}
            tableData={data}
            tableHeader={columns}
            dt={ref}
          />
          {loading ? (
            <SkeletonDataTable
              columns={[
                ...columns,
                { field: "full_info", header: "Full info" },
              ]}
            />
          ) : (
            <DataTable
              removableSort
              value={data.map((item) => {
                return {
                  ...item,
                  severity: SEVERITY_TEXT[item.severity],
                  status: STATUS_TEXT[item.status],
                };
              })}
              paginator
              rows={10}
              ref={ref}
              sortField="severity"
              sortOrder={-1}
              sortMode="multiple"
              globalFilter={globalFilter}
              paginatorTemplate={paginationTemplate()}
              className={`${
                isDarkMode ? "dark-mode text-dark" : " text-color"
              } text-xs`}
            >
              {columns.map((col, i) => {
                if (col.field === "severity" || col.field === "status") {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                      body={(rowData) => (
                        <span
                          className={
                            col.field === "status"
                              ? STATUS_COLOR[rowData.status]
                              : SEVERITY_COLOR[rowData.severity]
                          }
                        >
                          {rowData[col.field]}
                        </span>
                      )}
                    />
                  );
                } else {
                  return (
                    <Column
                      key={i}
                      field={col.field}
                      header={col.header}
                      sortable
                    />
                  );
                }
              })}
              <Column
                header="Full info"
                exportable={false}
                body={(rowData) => (
                  <div className="flex gap-2">
                    <button onClick={() => setCurrentDetails(rowData)}>
                      <FiArrowUpRight size={20} color="#074EE8" />
                    </button>
                  </div>
                )}
              />
            </DataTable>
          )}
        </div>
      </>
    );
  };
  return (
    <GenericLayout>
      <div className="flex justify-between dashboard-header items-center">
        <p className="font-bold">
          {currentUrl.includes("compliance-cloud")
            ? "Compliance"
            : "Cloud Security Posture Management"}
        </p>
        <div className="flex justify-between">
          <button
            className="flex items-center gap-[6px] text-[#074EE8] text-base ml-4 px-3"
            onClick={() => {
              // navigate("/cloud-security-posture-management");
              navigate(`/${currentUrl.split("/").slice(3, -1).join("/")}`);
            }}
          >
            Go to dashboard
            <TbArrowUpRight />
          </button>
        </div>
      </div>
      {returnTable()}
    </GenericLayout>
  );
}
