import { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import TableApi from "../../../Api/AuthenticationApi/TableApi";
import { toast } from "react-toastify";
import { getUserDetails } from "../../../redux-toolkit/redux/auth";
// import { message } from "antd";
// import { Toast } from "primereact/toast";
// import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { getSidebarVisible } from "../../../redux-toolkit/redux/TableData";

export const MODULES = [
  {
    name: "Compliance",
    submodules: [
      "Framework",
      "Controls",
      "Cloud Security",
      "People",
      "Policy",
      "Evidence",
      "Vendor Management",
      "Risk Management",
      "Vault",
      "Trust Center",
    ],
  },
  {
    name: "Cloud Security Posture Management",
    submodules: ["Services", "Compliance - CSPM", "Assign to Fix"],
  },
  {
    name: "Attack Surface Management",
    submodules: [
      "Assets",
      "Cloud Surface",
      "Vulnerability Intelligence",
      "Compliance - ASM",
      "Hosts",
      "DNS",
      "VOC",
      "Assign Task in VOC",
    ],
  },
  {
    name: "Digital Risk Protection",
    submodules: [
      "VIP Protection",
      "Darkweb",
      "Secrets Leak",
      "Fake Apps",
      "Phishing Domain Detection",
      "Brand Threat - Social media",
      "Action Button",
    ],
  },
  {
    name: "Brand Threat Intelligence",
    submodules: [
      "VIP Protection",
      "Brand Threat - Social media",
      "Action Button",
    ],
  },
];

const CreateUserForm = (props) => {
  const userDetails = useSelector(getUserDetails);
  const {
    role,
    handleCloseSideBar,
    setVisibleRight,
    orgUsers,
    getUserInformationTableData,
  } = props;
  // const dispatch = useDispatch();

  const { state } = useLocation();
  const user = state?.user?.id;
  // const toast = useRef(null);
  // const [selectedRole, setSelectedRole] = useState(null);
  // const [userEmail, setUserEmail] = useState("");

  const [data, setData] = useState({
    // first_name: null,
    // last_name: null,
    email: null,
    role: null,
    users: null,
    modules: [],
  });
  const [error, setError] = useState({
    // first_name: null,
    // last_name: null,
    email: null,
    role: null,
  });

  // const [userErrorEmail, setUserErrorEmail] = useState(false);
  // const [selectErrorRole, setSelectErrorRole] = useState(false);

  const validateEmail = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.match(mailformat)) {
      return true;
    }
    return false;
  };

  const handleCreateUser = (e) => {
    e.preventDefault();
    console.log("handleCreateUser");
    console.log("data", data);
    let payload = {};
    if (userDetails.role === "Super Admin" && data.role === "Auditor") {
      payload = { ...data };
    } else {
      payload = {
        email: data.email,
        role: data.role,
        editor: data.modules,
      };
    }
    console.log("payload", payload);
    for (const key in payload) {
      if (data[key] === null || data[key] === "") {
        setError({ ...error, [key]: `${key.replace("_", " ")} is required` });
        return;
      }
    }

    const validEmail = validateEmail(data["email"]);
    if (validEmail) {
      TableApi.createUser({ ...payload, user }).then((data) => {
        if (data.status === "success") {
          toast.success("User Created Successfully");
          // message.success(data.message)
          setVisibleRight(false);
          getUserInformationTableData();
        } else {
          toast.error(data.message);
        }
      });
    } else {
      toast.error("Invalid email address");
    }
  };

  const handleSubmodule = (item) => {
    if (data?.modules?.includes(item)) {
      setData({
        ...data,
        modules: data.modules.filter((i) => i !== item),
      });
    } else {
      setData({
        ...data,
        modules: [...data.modules, item],
      });
    }
  };

  const handleModule = (item) => {
    if (item.submodules.every((i) => data.modules.includes(i))) {
      setData({
        ...data,
        modules: data.modules.filter((i) => !item.submodules.includes(i)),
      });
    } else {
      setData({
        ...data,
        modules: [
          ...data.modules,
          ...item.submodules.filter((i) => !data.modules.includes(i)),
        ],
      });
    }
  };

  return (
    <>
      <form className="py-5 flex flex-col w-full login-style">
        {/* <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">First name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="John"
              value={data.first_name}
              required
              onChange={(e) => {
                setData({ ...data, first_name: e.target.value });
                setError({ ...error, first_name: false });
              }}
            />
            {error.first_name && (
              <p className="text-red-500 text-sm">{error.first_name}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Last name</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="text"
              placeholder="Doe"
              value={data.last_name}
              onChange={(e) => {
                setData({ ...data, last_name: e.target.value });
                setError({ ...error, last_name: false });
              }}
            />
            {error.last_name && (
              <p className="text-red-500 text-sm">{error.last_name}</p>
            )}
          </div>
        </div> */}
        <div className="flex flex-wrap -mx-3 mb-2">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Email</label>
            <input
              className={` placeholder-color w-full border-[1px] h-[40px] border-[#AAAAAA] block my-1 py-2 px-4 rounded-[16px] focus:outline-none focus:ring-[1px] focus:border focus:ring-[#686868]`}
              type="email"
              placeholder="john@email.com"
              value={data.email}
              required
              onChange={(e) => {
                setData({ ...data, email: e.target.value });
                setError({ ...error, email: false });
              }}
            />
            {error.email && (
              <p className="text-red-500 text-sm">{error.email}</p>
            )}
          </div>
        </div>
        <div className="flex flex-wrap -mx-3 mb-2 focus:ring-[1px] focus:border focus:ring-[#686868]">
          <div className="w-full px-3">
            <label className="block text-gray-500 text-sm ">Role</label>
            <Dropdown
              value={data.role}
              onChange={(e) => {
                setData({ ...data, role: e.value });
                setError({ ...error, role: false });
              }}
              options={[
                { name: "Auditor", value: "Auditor" },
                { name: "Editor", value: "Editor" },
                { name: "Employee", value: "Employee" },
              ]}
              optionLabel="name"
              required
              placeholder="Select a Role"
              className=" w-full md:w-14rem h-[40px] my-1"
            />
            {error.role && <p className="text-red-500 text-sm">{error.role}</p>}
          </div>
        </div>
        {userDetails.role === "Super Admin" && data.role === "Auditor" ? (
          <div className="flex flex-wrap -mx-3 mb-2">
            <div className="w-full px-3">
              <label className="block text-gray-500 text-sm ">
                Organisation
              </label>
              <MultiSelect
                className="w-full"
                optionLabel="name"
                optionValue="id"
                filter
                value={data.users}
                options={orgUsers}
                placeholder="Select Users"
                onChange={(e) => setData({ ...data, users: e.value })}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {data.role === "Editor" && (
          <>
            <p className="font-bold text-[16px] text-[#111]">Module</p>

            {MODULES.map((module) => {
              if (
                module.name === "Attack Surface Management" &&
                !userDetails.asm
              )
                return null;
              else if (
                module.name === "Digital Risk Protection" &&
                !userDetails.drp
              )
                return null;
              else if (
                module.name === "Brand Threat Intelligence" &&
                !userDetails.bti
              )
                return null;
              else if (
                module.name === "Cloud Security Posture Management" &&
                !userDetails.cspm
              )
                return null;
              else if (module.name === "Compliance" && !userDetails.compliance)
                return null;

              return (
                <>
                  <div
                    className="flex items-center gap-2 pt-2"
                    onClick={() => handleModule(module)}
                  >
                    <input
                      id={module.name}
                      type="radio"
                      checked={
                        module.submodules.every((i) =>
                          data.modules.includes(i)
                        ) || false
                      }
                      className="h-5 w-5 bg-red-500"
                    />
                    <label
                      className={`text-base font-normal ${
                        true ? "text-color" : "text-inactive"
                      }`}
                      htmlFor={module.name}
                    >
                      {module.name}
                    </label>
                  </div>
                  {module.submodules?.map((item) => {
                    return (
                      <div
                        key={item}
                        className="flex items-center gap-2 py-1 ml-[28px]"
                        onClick={() => handleSubmodule(item)}
                      >
                        <input
                          type="checkbox"
                          className="h-4 w-4"
                          checked={data?.modules?.includes(item) || false}
                        />
                        <label
                          className={`text-base font-normal ${
                            true ? "text-color" : "text-inactive"
                          }`}
                        >
                          {item}
                        </label>
                      </div>
                    );
                  })}
                </>
              );
            })}
          </>
        )}
        <div className="flex w-full mt-9">
          <button
            className="p-1 border  rounded-[16px] border-[#AAAAAA] h-[40px] w-full bg-[#181818] text-[#ffff]"
            onClick={(e) => handleCreateUser(e)}
            // type="submit"
          >
            Invite
          </button>
          <button
            className="p-1 ml-2 border rounded-[16px] border-[#AAAAAA] h-[40px] w-full "
            onClick={handleCloseSideBar}
          >
            Back
          </button>
        </div>
      </form>
    </>
  );
};

export default CreateUserForm;
