import { useState, useContext, createElement } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import GenericLayout from "../../common/layout/GenericLayout";
import UserTable from "./UsersTable";
import AdminTable from "./AdminTable";
import MyProjects from "../home/components/MyProjects";
import Scans from "./scans/Scans";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../redux-toolkit/redux/auth";
import { isAdminOrSuperAdmin } from "../../utils/accessCheck";

export default function AdminDashBoard() {
  const [currentTab, setCurrentTab] = useState("users");
  const { isDarkMode } = useContext(ThemeContext);
  const userDetails = useSelector(getUserDetails);

  const TABS = [
    {
      id: "users",
      title: "Users",
      component: UserTable,
    },
    {
      id: "admin",
      title: "Admin",
      component: AdminTable,
    },
    {
      id: "targets",
      title: "Targets",
      component: MyProjects,
    },
    {
      id: "scans",
      title: "Scans",
      component: Scans,
    },
  ];
  return (
    <GenericLayout>
      <div
        className={`flex gap-5 border-b-2 mt-7 mx-2 border-[#DEDEDE] ${
          isDarkMode ? "tab-dark-border" : "border-color"
        }`}
      >
        {TABS.map((tab) => {
          if (tab.id === "admin" && !isAdminOrSuperAdmin(userDetails.role))
            return null;
          return (
            <div
              key={tab.id}
              className="cursor-pointer py-2"
              onClick={() => setCurrentTab(tab.id)}
            >
              <p
                className={`capitalize text-base cursor-pointer ${
                  isDarkMode ? "text-[#FCFCFC] " : "text-[#111]"
                }  ${
                  currentTab === tab.id
                    ? "selected-tab-color font-medium"
                    : "font-normal"
                }`}
              >
                {tab.title}
              </p>
              <div
                className={`${
                  currentTab === tab.id
                    ? "selected-tab-color relative border-b-2"
                    : ""
                } top-[0.53rem] `}
              ></div>
            </div>
          );
        })}
      </div>
      <div className="py-4 px-2">
        {createElement(TABS.find((tab) => tab.id === currentTab)?.component, {
          data: TABS.find((tab) => tab.id === currentTab)?.props,
        })}
      </div>
    </GenericLayout>
  );
}
